import styled, { css } from "styled-components";

export const $ContentbulletList = styled.div`
  padding: 1em;
  .contentblock-container {
    padding: 15px 0;
    padding-bottom: 0;
    div {
      /* margin-bottom: 10px; */
    }
    label {
      font-size: small;
      max-width: 500px;
    }
    h3 {
      margin-bottom: 10px;
      border-bottom: 1px solid #eb5536;
      padding: 0.4em 0;
    }
    ul {
      list-style: none;
      padding: 0;
    }
    p,
    .list {
      /* margin: 0.5em 0; */
      padding: 0.5em;
      display: flex;
      align-items: center;
      @media only screen and (max-width: 768px) {
        font-size: 15px;
      }
      .icon-advantage {
        margin-right: 1em;
        transform: rotate(180deg);
        div {
          svg {
            fill: #4fbec7;
          }
        }
      }
    }
  }
`;
